/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';
import { useMemo, useEffect } from 'react';
// import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
// import { useAuthentication } from '../../utils/store';
import { getFromSession } from '../../utils/session';
import { useAuthentication } from '../../utils/store';
import useAuthenticationApi from '../Authentication/api';
import Icon from '../../components/Icon';
import PlayPodcast from '../../components/PlayPodcast';

export default function Dashboard() {
  const navigate = useNavigate();
  const {
    user, token,
  } = useAuthentication();
  const { getParent } = useAuthenticationApi();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);
  const excludedSubs = [
    'app',
    'staging',
    'onboarding',
    'www',
  ];

  const onSignup = () => {
    const host = window.location.href;
    const uri = new URL(host);
    const { hostname } = uri;
    
    const hostParts = hostname.split('.');
    if (hostParts.length > 2) {
      const [_u] = hostParts;
      if (_u && excludedSubs.includes(_u)) {
        if (signupParent) {
          navigate(`/dashboard/signin?sponsor=${signupParent?.SponsorName}`);
        } else {
          navigate('/dashboard/signin');
        }
      } else {
        const url = `${process.env.REACT_APP_HOST}/dashboard/signin?sponsor=${signupParent?.SponsorName}`;
        window.location.href = url;
      }
    } else if (hostParts.length <= 2) {
      if (signupParent) {
        navigate(`/dashboard/signin?sponsor=${signupParent?.SponsorName}`);
      } else {
        navigate('/dashboard/signin');
      }
    }
  };

  const onSignIn = () => {
    const host = window.location.href;
    const uri = new URL(host);
    const { hostname } = uri;
    const hostParts = hostname.split('.');
    
    if (hostParts.length > 2) {
      const [_u] = hostParts;

      if (_u && excludedSubs.includes(_u)) { // we are on staging
        if (signupParent) {
          navigate(`/signin?sponsor=${signupParent?.SponsorName}`);
        } else {
          navigate('/signin');
        }
      } else {
        const url = `${process.env.REACT_APP_HOST}/signin?sponsor=${signupParent?.SponsorName}`;
        window.location.href = url;
      }
    } else if (hostParts.length <= 2) {
      if (signupParent) {
        navigate(`/signin?sponsor=${signupParent?.SponsorName}`);
      } else {
        navigate('/signin');
      }
    }
  };

  useEffect(() => {
    const host = window.location.href;
    const uri = new URL(host);

    if (!signupParent && uri.hostname.split('.').length > 2) {
      const [_u] = uri.hostname.split('.');

      if (_u && !excludedSubs.includes(_u)) {
        const _fetch = async () => {
          try {
            const res = await getParent(_u);
            sessionStorage.setItem('signupParent', JSON.stringify(res));
            navigate('/');
          } catch (err) {
            if (err === 'Not Found') {
              navigate('/dashboard/registration/new');
            } else {
              console.log('err--', err);
              toast.error(err);
            }
          }
        };

        _fetch();
      }
    }
  }, [signupParent]);

  useEffect(() => {
    if (user && token) {
      navigate('/home');
    }
  }, []);

  if (user && token) {
    return '';
  }

  return (
    <div className="dashboard">
      <div className="nav-cont">
        <div className="img-cont">
          <img src="/assets/MemeGames-logo-white.png" alt="memegames-logo" />
        </div>

        <div className="button-cont flex-1 flex">
          {signupParent ? (
            <Button
              onClick={onSignup}
              icon={<Icon name="SignUpAs" width={17} height={17} />}
              size="sm"
            >
              <Typography sx={{ fontWeight: '500', alignSelf: 'center' }}>
                <span style={{ color: 'white', fontWeight: 400 }}>
                  Sign Up with:
                </span>
                {signupParent?.firstName && signupParent?.lastName ? ` ${signupParent?.firstName || ''} ${signupParent?.lastName || ''} ` : ` ${signupParent.username}`}
              </Typography>
            </Button>
          ) : null}

          <Button
            onClick={onSignIn}
            bordered
            icon={<Icon name="UserIcon" width={17} height={17} />}
            size="sm"
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: '500',
                alignSelf: 'center',
              }}
            >
              Login
            </Typography>
          </Button>
        </div>
      </div>

      <div className="container-1">
        <div className="text-container">
          <Typography className="text-1">MEMEGAMES.AI</Typography>

          <Typography className="text-2">
            The Ultimate MemeCoin Competition!
          </Typography>

          <Typography className="text-3">
            Dive into the electrifying world of MemeGames! Join now and master the art of trading memecoins.
            It's all about strategy, analysis, and split-second decisions. Are you skilled enough to win one of our games?
          </Typography>

          <PlayPodcast />
        </div>

        <div className="image-container">
          <img src="/images/smile.png" alt="smmile" />
          <div className="smile-shadow" />
        </div>
      </div>

      <Grid container className="container-2" justifyContent="space-between">
        <img src="/images/smile-half-co.png" alt="smmile" className="bg-img" />

        <Grid
          item
          xl={12}
          lg={12}
          className="top-section"
          justifyContent="center"
        >
          <Typography className="text-1">
            Predict trending MemeCoins, sharpen your market skills, and earn MG Tokens by finishing a game among the top 10 percent of all players.  Whether you're a crypto pro or just starting out, MemeGames makes learning fun and rewarding. If you earn enough tokens, you can redeem them for real MemeCoins on the Solana blockchain. Although those coins may be connected to a decentralized ledger and publicly traded, they have no actual value and are intended for entertainment and educational purposes only. If you redeem MG Tokens for MemeCoins, do not expect those coins to increase in value.
          </Typography>
        </Grid>

        <Grid item xl={6} lg={6} className="left-section">
          <img className="laptop" src="/images/laptop.png" alt="laptop" />

          <div className="text-card">
            <div className="flex mb">
              <img src="/icons/lp-coins.svg" alt="123" />
              <Typography className="info mb">
                <span className="primary-color">Real-Time Market Data:</span>
                {' '}
                Access current price, 24-hour volume, market cap, and more.
              </Typography>
            </div>

            <div className="flex mb">
              <img src="/icons/lp-users.svg" alt="123" />
              <Typography className="info mb">
                <span className="primary-color">Social Media Insights:</span>
                {' '}
                Analyze the latest trends from each coin's Twitter feed.
              </Typography>
            </div>

            <div className="flex mb">
              <img src="/icons/lp-charts.svg" alt="123" />
              <Typography className="info">
                <span className="primary-color">Historical Performance:</span>
                {' '}
                Analyze a performance graph to guide your decisions.
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          sx={{ sm: { mt: '500px' } }}
          className="right-section"
        >
          <Typography className="text-1">
            Compete Daily in Our Memecoin Challenge!
          </Typography>

          <Typography className="text-2">
            {'A new game begins every 8 hours and runs for an entire day, resulting in 3 ongoing games at all times. In each game, two trending MemeCoins are presented, and it\'s your job to pick for the one you think will perform better over the course of the day. Stay on your toes and keep your analysis sharp because every day brings new challenges and opportunities.'}
          </Typography>
          <Box sx={{
            color: 'var(--primary-color)', display: 'flex', justifyContent: 'start', mb: 3, width: '100%', ml: 5,
          }}
          >
            <ul style={{
              listStyleType: 'circle',
            }}
            >
              <li>
                <Typography sx={{ fontSize: '18px', mb: 0.5 }}>
                  Pick a coin when the time is right
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: '18px', mb: 0.5 }}>
                  Earn up to 10x multiplier on your score
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: '18px', mb: 0.5 }}>
                  Top players in every competition earn MG Tokens
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: '18px', mb: 0.5 }}>
                  Redeem your tokens for real memecoins.
                </Typography>
              </li>
            </ul>
          </Box>

          <div className="flex w-full">
            <Button
              size="sm"
              style={{ width: ' fit-content' }}
              onClick={onSignup}
            >
              <Typography sx={{ mx: '15px' }}>Become a Gamer Today!</Typography>
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid container className="container-3" justifyContent="space-between">
        <img src="/images/A.png" alt="smmile" className="bg-img" />

        <Grid
          item
          xl={6}
          lg={6}
          sx={{ sm: { mt: '500px' } }}
          className="right-section"
        >
          <Typography className="text-1 w-full">Cast Your Pick</Typography>

          <Typography className="text-2">
            When you choose between the two MemeCoins presented in a game, we immediately record the value of that coin. At the end of each day's game, if your MemeCoin outperforms the other coin featured that day, you are eligible to be among the 10% of players who are declared the game’s winners. By picking the winning coin, your score increases by multipliers based on the percentage your selected coin’s value increases between the time you enter and exit the game. So do you research and analysis, and pick the coin you think is on the way up!
          </Typography>

          <div className="w-full">
            <Button
              size="sm"
              style={{ width: ' fit-content' }}
              onClick={onSignup}
            >
              <Typography sx={{ mx: '15px' }}>Become a Gamer Today!</Typography>
            </Button>
          </div>
        </Grid>

        <Grid item xl={6} lg={6} className="left-section">
          <img src="/images/device.png" alt="laptop" />
          <div className="text-card">
            <div className="flex mb">
              <img src="/icons/lp-checkcont.svg" alt="123" />
              <Typography className="info">
                Pick for the winning MemeCoin
              </Typography>
            </div>

            <div className="flex mb">
              <img src="/icons/lp-clock.svg" alt="123" />
              <Typography className="info">
                Time your pick for maximum impact
              </Typography>
            </div>

            <div className="flex mb">
              <img src="/icons/lp-tasks.svg" alt="123" />
              <Typography className="info">
                See how your competitors picked
              </Typography>
            </div>

            <div className="flex">
              <img src="/icons/lp-medal.svg" alt="123" />
              <Typography className="info">
                Winners earn MG Tokens
              </Typography>
            </div>
          </div>
          <div className="shadow" />
        </Grid>
      </Grid>

      <Grid
        sx={{ mt: '200px' }}
        container
        className="container-4"
        justifyContent="space-between"
      >
        <img src="/images/yll.png" alt="smile" className="bg-img" />

        <Grid item xl={6} lg={6} className="left-section">
          <img src="/images/iphone.png" alt="iphone" />

          <div className="text-card">
            <div className="flex mb">
              <img src="/icons/lp-hand.svg" alt="hand-money" />
              <Typography className="info mb">
                Receive 200 MG Coins with each pass
              </Typography>
            </div>

            <div className="flex mb">
              <img src="/icons/lp-dollar.svg" alt="dollar" />
              <Typography className="info mb">
                Use MG Coins to earn MG Tokens
              </Typography>
            </div>

            {/*
            <div className="flex mb">
              <img src="/icons/lp-pie.svg" alt="123" />
              <Typography className="info mb">
                Grow your GF to build your power
              </Typography>
            </div> */}

            <div className="flex">
              <img src="/icons/lp-trophy.svg" alt="trophy" />
              <Typography className="info">
                Redeem your MG Tokens for real MemeCoins
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          sx={{ sm: { mt: '500px' } }}
          className="right-section"
        >
          <Typography className="text-1 w-full">MG COINS and Tokens</Typography>

          <Typography className="text-2">
            For every $100 pass, receive 100 MG Coins. Use these Coins to participate in our daily games. When your game finishes, the top 10% highest scorers will receive their point value in MG Tokens. If you earn enough MG Tokens, you can redeem them for any MemeCoin you’ve added to your winning library. When you redeem your tokens, MemeGames will send your selected MemeCoins directly to your Solana wallet. But remember, MemeCoins have no actual value and are intended for entertainment and educational purposes only.
          </Typography>

          <Typography sx={{
            fontSize: '18px', fontWeight: 'bold', color: 'var(--primary-color)', mb: 2,
          }}
          >
            *Special Offer: Receive an extra 100MG coins per pass, while it lasts!
          </Typography>

          <div className="w-full">
            <Button
              size="sm"
              style={{ width: ' fit-content' }}
              onClick={onSignup}
            >
              <Typography sx={{ mx: '15px' }}>Become a Gamer Today!</Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
      {/*
      <div className="container-5">
        <div className="flex cont-head">
          <div>
            <h2>Multipliers</h2>
            <div className="flex">
              <Button size="md" onClick={onBecomeGamerClick}>
                Become a Gamer
              </Button>
            </div>
          </div>
          <div className="flex-1">
            <p>
              Multipliers are applied to your MG Tokens total at the end of each
              daily game. The performance multiplier add 1x for every percentage
              point your chosen coin performs on the market through the rest of
              the day after the moment you pick it! For example, if your chosen
              coin improves 7% after you pick it, you receive a 7x multiplier on
              the MG Coins you picked. Pick your coin at the right time to gain
              the biggest multiplier and level the competition.
            </p>
          </div>
        </div>

        <div className="cont-body flex justify-center">
          <div style={{ display: 'flex', maxWidth: '520px ', marginLeft: '13vw' }} className="mult-cont">
            <img src="/icons/lp-checkbox.svg" alt="checkbox" style={{ marginRight: '15px', marginTop: '10px' }} />
            {/* <h3>Speed Multiplier</h3> */}
      {/* <p>
              Gain a multiplier for every percentage point your chosen coin
              performs in a day's game above the moment when you picked it!
            </p>
          </div> */}
      {/* <div className="mult-cont">
            <img src="/icons/lp-checkbox.svg" alt="checkbox" />
            <h3>Performance</h3>
            <p>10x if your coin rises at least 10% after you pick</p>
          </div>
          <div className="mult-cont">
            <img src="/icons/lp-checkbox.svg" alt="checkbox" />
            <h3>Streak</h3>
            <p>An additional multiplier for every day of a winning streak</p>
          </div> */}
      {/* </div>
      </div> */}

      {/* <div className="container-6">
        <div className="flex-1 cont-body">
          <div className="left flex-1">
            <h2>Winner Experiences</h2>

            <p>
              Daily winners will be invited to enjoy worldwide luxury
              experiences. The more you win, the more invites you will receive.
              Our first experience will be a MemeGames meetup on a MegaYacht
              later this summer.
            </p>
          </div>
          <div className="right flex-1 flex flex-column">
            <div className="right-cont">
              <div className="border" />
              <h4>Worldwide luxury experiences</h4>
            </div>
            <div className="right-cont">
              <div className="border" />
              <h4>First experience: A MegaYacht experience in Summer 2024</h4>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-5 ambassadors">
        <div className="flex cont-head">
          <h2>Ambassadors</h2>
        </div>

        <div className="flex cont-body">
          <div className="mult-cont">
            <h3>Get Paid Daily</h3>
            <p>
              Score daily commissions with our ranking system. The more gamers you bring in who play the game, the bigger your cut. Rank up and earn more!
            </p>
          </div>
          <div className="mult-cont">
            <h3>Build Your Army</h3>
            <p>
              Recruit more affiliates to boost your total volume and snag higher commissions when the gamers they bring in play the game.
            </p>
          </div>
          <div className="mult-cont">
            <h3>Direct Sales</h3>
            <p>
              Gain personal sales volume on every direct sale you make. The more you sell to gamers who play the game, the more you earn. It's that simple!
            </p>
          </div>
          <div className="mult-cont">
            <h3>
              Weekly Gamification Affiliate Webinar
            </h3>
            <p>
              Join our weekly webinars to learn how to maximize your sales and participate in group sales marketing projects
            </p>
          </div>
          {/* <div className="mult-cont">
            <h3>Daily Leadership Zoom Meetings</h3>
            <p>
              Daily IG live with
              {' '}
              <a
                href="https://www.instagram.com/memegamesai/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="primary">@MemeGamesai</span>
              </a>
            </p>
          </div> */}
          {/* <div className="mult-cont">
            <h3>Direct Sales</h3>
            <p>Receive commissions</p>
          </div> */}
        </div>
      </div>

      <div className="container-7 flex-column">
        <div className="cont-head">
          <h1>Affordable Access</h1>
        </div>

        <div className="flex-1 cont-body">
          <div className="left flex-1">
            <h2>
              Join the Game for just $100, and receive an extra 100 MG coins while our promotion lasts!
            </h2>

            <p>
              Become a Gamer today and get 200 MG Coins! Dive into a thrilling world of competition, strategy, and crypto education.
            </p>
          </div>
          {/* <div className="right flex-1">
            <h2>Become a Gamer</h2>
            <p>
              A 10-day pass to join the game is just $100. Gain access to an
              exciting world of competition, strategy, and education.
            </p>
          </div> */}
        </div>
      </div>

      <div className="container-8 flex flex-column">
        <div className="card">
          <div className="flex justify-center">
            <h1>Gamer Pack $100</h1>
          </div>
          <div className="flex justify-center">
            <Button className="md" bordered onClick={onSignup}>
              Become a Gamer Today!
            </Button>
          </div>
        </div>

        <div className="flex justify-center">
          <img src="/images/white-logo.png" alt="white-logo" />
        </div>
        <div className="flex justify-center">
          <span>
            ©
            {new Date().getFullYear()}
            {' '}
            MemeGames.ai. All rights reserved.
          </span>
        </div>
      </div>
    </div>
  );
}
