/* eslint-disable indent */
import { useContext, useEffect, useState } from 'react';
import {
  Container, Grid, Slider, Typography,
} from '@mui/material';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import { modalTypes } from '../config';
import Button from '../../../components/Button';
import RadioGroup from '../../../components/Inputs/RadioGroup';
import { AppContext } from '../../../utils/store/app';

const withdrawIncrement = 100;

export default function WithdrawModal() {
  const {
    modalData,
    onModalClose,
    onWithdrawSubmit,
    defaultWallet,
    solanaWallet,
  } = useContext(Context);

  const { passTypes, packages } = useContext(AppContext);

  const filteredPassTypes = [
    ...passTypes.filter(
      (p) => p.active && p.price > 0 && p.price % withdrawIncrement === 0,
    ),
    ...packages,
  ];

  const [minWithdrawAmount, setMinWithdrawAmount] = useState(withdrawIncrement);
  const [withdrawAmount, setWithdrawAmount] = useState(withdrawIncrement);
  const [withdrawType, setWithdrawType] = useState('solana');

  const [selectedPassTypeId, setSelectedPassTypeId] = useState(null);
  const [selectedIsBundle, setSelectedIsBundle] = useState(false);

  const handleWithdrawButtonSubmit = () => {
    const passTypeId = selectedPassTypeId || filteredPassTypes[0].id;
    const selectedPassType = filteredPassTypes.find((pt) => pt.id === passTypeId);

    onWithdrawSubmit({
      withdrawType,
      withdrawAmount,
      gamePass: selectedPassType,
    });

    onModalClose();
  };

  const handleSelectedPassTypeChange = (id) => {
    setSelectedPassTypeId(id);

    const selected = filteredPassTypes.find((pt) => pt.id === id);

    const newPrice = selected.price || selected.Price;

    setMinWithdrawAmount(newPrice);
    setWithdrawAmount(newPrice > defaultWallet?.balance ? withdrawIncrement : newPrice);

    setSelectedIsBundle(selected.Price > 0);
  };

  useEffect(() => {
    setMinWithdrawAmount(withdrawIncrement);
    setWithdrawAmount(withdrawIncrement);
    setSelectedPassTypeId(filteredPassTypes[0].id);
    setSelectedIsBundle(false);
  }, [withdrawType]);

  if (modalData?.type === modalTypes.WITHDRAW) {
    return (
      <Modal
        width="650px"
        title={modalData.description}
        open={!!modalData}
        handleClose={onModalClose}
      >
        <Typography
          className="text-center"
          sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 2 }}
        >
          Redeem can only be performed
          {' '}
          <br />
          {`in $${withdrawIncrement} increments`}
        </Typography>

        {!!defaultWallet && defaultWallet.balance >= withdrawIncrement ? (
          <Container>
            <RadioGroup
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                color: '#FFFFFF',
              }}
              onChange={(e) => {
                setWithdrawType(e);
              }}
              value={withdrawType}
              setErrors={() => { }}
              name="withdrawType"
              options={[
                { label: 'To Solana Wallet', value: 'solana' },
                { label: 'Redeem for Coin Packs', value: 'passes' },
              ]}
            />

            {withdrawType === 'solana'
              && (!solanaWallet ? (
                <Typography className="text-center">
                  You need to connect your Solana Wallet to the system
                </Typography>
              ) : (
                <>
                  <Typography className="text-center">
                    {`Transferring $${withdrawAmount} to your solana Wallet`}
                  </Typography>
                  <Typography className="text-center" style={{ color: 'grey' }}>
                    {` ${solanaWallet.publicKey}`}
                  </Typography>
                </>
              ))}

            {withdrawType === 'passes' && (
              <RadioGroup
                onChange={(e) => {
                  handleSelectedPassTypeChange(e);
                }}
                value={selectedPassTypeId}
                defaultValue={selectedPassTypeId || filteredPassTypes[0].id}
                setErrors={() => { }}
                name="selectedPassTypeId"
                options={filteredPassTypes.map((p) => ({
                  label: `${p.name} ($${p?.price || p?.Price}) ${p?.price
                    ? `(${Math.floor(withdrawAmount / p.price)} packs)`
                    : ''
                    }`,
                  value: p.id,
                }))}
              />
            )}

            <Grid sx={{ marginTop: 2 }}>
              <Slider
                min={minWithdrawAmount}
                max={
                  Math.floor(defaultWallet.balance / minWithdrawAmount)
                  * minWithdrawAmount
                }
                value={withdrawAmount}
                step={minWithdrawAmount}
                getAriaValueText={() => withdrawAmount}
                valueLabelDisplay="auto"
                onChange={(e, v) => {
                  setWithdrawAmount(v);
                }}
                disabled={defaultWallet.balance < minWithdrawAmount || selectedIsBundle}
              />
              <Button
                onClick={() => {
                  if (defaultWallet.balance >= minWithdrawAmount) {
                    handleWithdrawButtonSubmit();
                  }
                }}
                className="mt-3"
                disabled={defaultWallet.balance < minWithdrawAmount}
              >
                <span className="no-wrap">
                  Redeem (
                  {withdrawAmount}
                  )
                </span>
              </Button>
            </Grid>
          </Container>
        ) : (
          <Typography
            className="text-center"
            sx={{ marginTop: 2, marginBottom: 3 }}
          >
            {`Your current balance is $${defaultWallet?.balance || '0'}`}
          </Typography>
        )}
      </Modal>
    );
  }
}
