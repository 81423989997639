import Bell from './configs/Bell';
import SignUpAs from './configs/SignUpAs';
import SquaredQuestionmark from './configs/SquaredQuestionmark';
import GameEqualSign from './configs/GameEqualSign';
import GameMultiplication from './configs/GameMultiplication';
import Chart from './configs/Chart';
import ChartBlack from './configs/ChartBlack';
import Trophy from './configs/Trophy';
import PickWinner from './configs/PickWinner';
import PickWinnerBlack from './configs/PickWinnerBlack';
import Star from './configs/Star';
import User from './configs/User';
import Home from './configs/Home';
import Admin from './configs/Admin';
import Profile from './configs/Profile';
import Support from './configs/Support';
import ListItems from './configs/ListItems';
import Genealogy from './configs/Genealogy';
import Memberships from './configs/Memberships';
import DoubleChevronLeft from './configs/DoubleChevronLeft';
import Spy from './configs/Spy';
import Spy2 from './configs/Spy2';
import Wallet from './configs/Wallet';
import UserX from './configs/users-x';
import UserLupa from './configs/users-lupa';
import UserConfig from './configs/users-config';
import UserIcon from './configs/UserIcon';
import Money from './configs/money';
import ShoppingBag from './configs/ShoppingBag';
import People from './configs/people';
import Technology from './configs/technology';
import RealEstate from './configs/realEstate';
import Bitcoin from './configs/bitcoin';
import Ranks from './configs/Ranks';
import Dollar from './configs/Dollar';
import GraduationHat from './configs/graduationHat';
import TrecLogo from './configs/TrecLogo';
import Ranked from './configs/Ranked';
import Checkmark from './configs/Checkmark';
import ClockArrow from './configs/ClockArrow';
import MoneyHand from './configs/MoneyHand';
import ReportFile from './configs/ReportFile';
import Play from './configs/Play';
import Pause from './configs/Pause';

export default {
  Bell,
  Home,
  User,
  Admin,
  Profile,
  Support,
  ListItems,
  Genealogy,
  Memberships,
  DoubleChevronLeft,
  Spy,
  Spy2,
  Wallets: Wallet,
  UserX,
  UserLupa,
  UserConfig,
  Money,
  ShoppingBag,
  People,
  Technology,
  RealEstate,
  Bitcoin,
  Ranks,
  GraduationHat,
  TrecLogo,
  Star,
  Dollar,
  Trophy,
  Chart,
  PickWinner,
  PickWinnerBlack,
  Checkmark,
  UserIcon,
  SignUpAs,
  SquaredQuestionmark,
  GameEqualSign,
  GameMultiplication,
  Ranked,
  ClockArrow,
  MoneyHand,
  ChartBlack,
  ReportFile,
  Play,
  Pause,
};
