import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Container, Pagination, Typography } from '@mui/material';
import { PodcastContext, PodcastProvider } from './context';
import Layout from '../../../components/CardLayout';
import PodcastRow from './component/PodcastRow';
import ComponentLoader from '../../../components/ComponentLoader';

const PodcastPage = () => {
  const {
    t,
    user,

    getPodcastsData,
    podcastsData,
    podcastsDataLoaded,
    paginationData,
  } = useContext(PodcastContext);
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container maxWidth="xl" className="podcast-page" sx={{ paddingX: { xs: 0, lg: '16px' } }}>
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {t('Our Podcast')}
        </Typography>
      </Layout>
      <Layout
        maxWidth="100%"
        className="podcast-list"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
          gap: 8,
        }}
      >
        {!podcastsDataLoaded && <ComponentLoader />}
        {(podcastsDataLoaded && podcastsData.length > 0) ? (
          podcastsData.map((podcast, idx) => <PodcastRow podcast={podcast} key={idx} />)
        ) : (<p>Stay tunned.</p>)}
      </Layout>
      {(paginationData && paginationData.totalPodcasts > 25) && (
        <div className="flex justify-end mt-4">
          <Typography sx={{ mt: 0.5 }}>
            {`Total of: ${paginationData.totalPodcasts} episodes`}
          </Typography>
          <Pagination
            count={paginationData?.total}
            page={paginationData?.page}
            onChange={(val, page) => {
              getPodcastsData({ page });
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default () => (
  <PodcastProvider>
    <PodcastPage />
  </PodcastProvider>
);
