import React, { useState, useContext } from 'react';
import { Container, Typography } from '@mui/material';
import { Provider, Context } from './context';
// import Banner from '../../../components/Banner';
import Teams from './components/Teams';
import UsersTree from './components/Tree';
import UserModal from './components/UserModal';
import ActiveTeamModal from './components/ChangeTeamModal';
// import { PROMO_MESSAGES } from '../../../constants';
import useScreenSize from '../../../utils/hooks/useScreenSize';
import CustomAccordion from '../../../components/Accordion';

export function GenealogyTree() {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useScreenSize();
  const {
    stats,
  } = useContext(Context);

  return (
    <div>
      {/* <Banner data={PROMO_MESSAGES.mvpKickoff} /> */}
      {isMobile ? (
        <div className="teams-accordion-container">
          <CustomAccordion
            expanded={isOpen}
            setExpanded={() => setIsOpen((current) => !current)}
            summary={<p>My Team</p>}
            details={<Teams />}
          />
        </div>
      ) : (
        <Teams />
      )}
      <Container>
        <div className="flex row-items">
          <Typography variant="span">
            {`Ambassadors: ${stats?.totalAmbassadors || 0}`}
          </Typography>
          <Typography variant="span" sx={{ marginLeft: '20px' }}>
            {`Active Ambassadors: ${stats?.activeAmbassadors || 0}`}
          </Typography>
        </div>
      </Container>

      <div className="flex justify-center">

        <UsersTree />
      </div>
      <UserModal />
      <ActiveTeamModal />
    </div>
  );
}

export default () => (
  <Provider>
    <GenealogyTree />
  </Provider>
);
