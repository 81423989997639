export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const renderNumber = (val) => {
  const _val = Number(val);

  if (Number.isNaN(_val)) {
    return 0;
  }

  if (_val < 1) {
    return _val;
  }

  return _val.toLocaleString('en-us');
};

export const renderHourFromSeconds = (seconds) => {
  if (seconds < 1) return '00:01';
  
  const hour = Math.floor(seconds / 3600).toString().padStart(2, '0');
  const minute = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
  const second = Math.floor(seconds % 60) >= 59 ? '59' : Math.floor(seconds % 60).toString().padStart(2, '0');

  return Number(hour) > 0 ? `${hour}:${minute}:${second}` : `${minute}:${second}`;
};
