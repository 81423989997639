/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import drawerItems from '../../../../constants/drawerItems';
import { DRAWER_WIDTH } from '../../../../constants';
import CustomCollapseIcon from '../CollapseIcon';
import { useGeneral } from '../../../../utils/store';
import { AuthenticationContext } from '../../../../utils/store/authentication';

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      background: theme.palette.background.paper,
      [theme.breakpoints.only('xl')]: { position: 'relative' },
      [theme.breakpoints.only('lg')]: { position: 'relative' },
      [theme.breakpoints.only('md')]: { position: 'fixed' },
      [theme.breakpoints.only('sm')]: { position: 'fixed' },
      [theme.breakpoints.only('xs')]: { position: 'fixed' },
      whiteSpace: 'nowrap',
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      // eslint-disable-next-line react/jsx-props-no-spreading
      ...(!open && {

        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: 0,
        },
        [theme.breakpoints.up('xs')]: {
          width: 0,
        },
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const checkPath = ({ location, item }) => {
  if (item.children) {
    return !!item.children.find((ch) => location.pathname.includes(ch.path));
  }

  if (item.path === '/') {
    return location.pathname === '/home';
  }

  return location.pathname.includes(item.path);
};

const NestedListItem = ({
  item, isInFocus, isOpen, border, user, noPath, file,
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(location.pathname === item.path);
  }, [isOpen]);

  return (
    <>
      <ListItem
        noPath={noPath}
        path={item.path}
        border={border && !open}
        isInFocus={isInFocus}
        item={item}
        onClick={handleClick}
        target={item.target}
      >
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((chItem) => {
            if (!chItem.roles?.includes(user?.Role) && !chItem.roles?.includes('all')) {
              return '';
            }
            return (
              <ListItem
                target={chItem.target}
                path={chItem.path}
                isInFocus={checkPath({ location, item: chItem })}
                key={chItem.id}
                item={chItem}
                opacity={0.5}
                file={chItem.file || null}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const ListItem = ({
  item,
  children,
  onClick = () => { },
  isInFocus,
  border = true,
  opacity = 1,
  path,
  target = '',
  noPath,
  file = null,
}) => {
  const { theme } = useGeneral();

  if (noPath) {
    return (
      <div className={isInFocus ? 'in_focus' : 'out_focus'}>
        <ListItemButton
          title={item.label}
          onClick={onClick}
          sx={{ pl: 4, my: 0.5 }}
        >
          <ListItemIcon title={item.label}>
            {typeof item?.icon === 'function'
              ? item.icon(isInFocus, theme)
              : item?.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.label}
            sx={{ opacity: isInFocus ? 1 : opacity }}
          />
          {children}
        </ListItemButton>
        {border && <div className="gradient-divider" />}
      </div>
    );
  }

  if (file) {
    return (
      <a {...file} className={isInFocus ? 'in_focus' : 'out_focus'}>
        <ListItemButton title={item.label} onClick={onClick} sx={{ pl: 4, my: 0.5 }}>
          <ListItemIcon title={item.label}>
            {typeof item?.icon === 'function' ? item.icon(isInFocus, theme) : item?.icon}
          </ListItemIcon>
          <ListItemText primary={item.label} sx={{ opacity: isInFocus ? 1 : opacity }} />
          {children}
        </ListItemButton>
        {border && <div className="gradient-divider" />}
      </a>
    );
  }

  return (

    <Link target={target} to={path} className={isInFocus ? 'in_focus' : 'out_focus'}>
      <ListItemButton title={item.label} onClick={onClick} sx={{ pl: 4, my: 0.5 }}>
        <ListItemIcon title={item.label}>
          {typeof item?.icon === 'function' ? item.icon(isInFocus, theme) : item?.icon}
        </ListItemIcon>
        <ListItemText primary={item.label} sx={{ opacity: isInFocus ? 1 : opacity }} />
        {children}
      </ListItemButton>
      {border && <div className="gradient-divider" />}
    </Link>
  );
};

const Drawer = ({ toggleDrawer, open }) => {
  const location = useLocation();
  const { user } = useContext(AuthenticationContext);
  const [supportLink, setSupportLink] = useState('');

  const fetchHelp = async () => {
    const day = moment().format('DD');
    const month = moment().format('MM');
    const { SID, Email } = user;
    const sharedsecret = process.env.REACT_APP_HELPDESK_SECRET;
    const helpdeskUrl = process.env.REACT_APP_HELPDESK_URL;
    const preHash = SID + Email + sharedsecret + day + month;
    const hash = CryptoJS.MD5(preHash).toString(CryptoJS.enc.Hex);
    const tmp = `${helpdeskUrl}/helpdesk/User/AutoLogin?username=${SID}&email=${Email}&userHash=${hash}`;
    setSupportLink(tmp);
  };

  useEffect(() => {
    fetchHelp();
  }, [drawerItems]);

  return (
    <CustomDrawer open={open} variant="permanent">
      <Toolbar
        sx={{
          display: 'flex',
          paddingLeft: {
            sm: 0,
            lg: 0,
            xl: 0,
            md: 0,
          },
        }}
      >
        <CustomCollapseIcon toggleDrawer={toggleDrawer} />
        <img alt="logo" src="/assets/MemeGames-logo-white.png" height="36px" />
      </Toolbar>
      <List className="nav-list" sx={{ marginTop: '30px' }} component="nav">
        {drawerItems.map((item) => {
          if (!item?.roles.includes(user?.Role) && !item?.roles.includes('all')) {
            return '';
          }

          // 44EJ affiliate=true product=true
          // if (!user.isAffiliate && !item.isAffiliate && user.isProduct === item.isProduct && user.Role !== 'owner' && user.Role !== 'admin') {
          //   return '';
          // }

          // 11EJ affiliate=true product=false
          // if (user?.isAffiliate && item.isAffiliate && !user.isProduct === !item.isProduct && user.Role !== 'owner' && user.Role !== 'admin') {
          //   return '';
          // }

          // // 33EJ affiliate=false product=true
          // if (!user.isAffiliate && !item.isAffiliate && user.isProduct === item.isProduct && user.Role !== 'owner' && user.Role !== 'admin') {
          //   return '';
          // }
          if (typeof item.isAffiliate === 'boolean') {
            if (!(user.isAffiliate === item.isAffiliate)) {
              return '';
            }
          }
          if (typeof item.isProduct === 'boolean') {
            if (!(user.isProduct === item.isProduct)) {
              return '';
            }
          }

          if (item.children) {
            return (
              <NestedListItem
                item={item}
                key={item.id}
                isOpen={open}
                isInFocus={checkPath({ location, item })}
                border={open}
                user={user}
                noPath={item.noPath}
                file={item.file || null}
              />
            );
          }

          if (item.label === 'Support') {
            return (
              <ListItem
                path={supportLink}
                isInFocus={false}
                key={item.id}
                item={item}
                target={item.label === 'Support' ? '_blank' : ''}
              />
            );
          }

          if (item.type === 'external') {
            return (
              <ListItem
                path={item.path}
                isInFocus={false}
                key={item.id}
                item={item}
                target="_blank"
              />
            );
          }

          return (
            <ListItem
              path={item.label === 'Support' ? supportLink : item.path}
              isInFocus={checkPath({ location, item })}
              key={item.id}
              item={item}
              target={item.label === 'Support' ? '_blank' : ''}
            />
          );
        })}
      </List>
      {' '}
    </CustomDrawer>
  );
};

export default Drawer;
