import { PersonRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const UserAccordionSummary = ({ image, name, expiration }) => {
  const getExpirationClass = () => {
    if (!expiration) {
      return '';
    }

    const today = dayjs();
    const expirationDays = dayjs(expiration).diff(today, 'day');
    let expirationClass = expirationDays <= 7 ? ' expiring' : '';
    expirationClass = expirationDays <= 0 ? ' expired' : expirationClass;

    return expirationClass;
  };

  return (
    <div className="user-accordion-summary">
      <div className="image">
        {!image ? (
          <PersonRounded fontSize="large" />
        ) : (
          <img
            src={image}
            alt="Profile pic"
            className="profile-pic"
            height="44"
          />
        )}
      </div>
      <div className="info">
        <Typography className={`date-expiration${getExpirationClass()}`}>
          {!expiration ? '' : dayjs(expiration).format('MMMM DD, YYYY')}
        </Typography>
        <h3>{name}</h3>
      </div>
    </div>
  );
};

export default UserAccordionSummary;
