/* eslint-disable react/jsx-props-no-spreading */
import {
  Container,
  // Slider, SliderThumb, Tooltip, 
  Typography,
} from '@mui/material';
import {
  useContext, useMemo,
} from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Context } from '../../../context';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import ComponentLoader from '../../../../../components/ComponentLoader';
import { renderNumber } from '../../../../../utils/string';
import Icon from '../../../../../components/Icon';

export default function VoteModal({ renderCont = () => null, gameId }) {
  const {
    chosenMemeCoin: chosenMemeCoins, onVoteMemeCoin, loadingCoins, openMemeConfirmModal,
    gamingFunds, setOpenMemeConfirmModal, todayGame: todayGames,
  } = useContext(Context);

  const coin = useMemo(() => openMemeConfirmModal, [openMemeConfirmModal]);
  // const { getPercentageMultiplier } = usePickPoints();
  const todayGame = todayGames.find((g) => g.id === gameId);
  // const [wage, setWage] = useState(todayGame?.price || 0);
  const cannotVote = !gamingFunds || todayGame?.price > gamingFunds;

  const chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === gameId);

  const numbersData = useMemo(() => {
    let currentPrice = Number(coin?.data?.market_data?.current_price?.usd);

    if (currentPrice < 1) {
      currentPrice = Number(coin?.data?.market_data?.current_price?.usd).toFixed(8);
    } else {
      currentPrice = renderNumber(coin?.data?.market_data?.current_price?.usd);
    }

    let _startData = Number(coin?.start?.price);

    if (!_startData) {
      _startData = Number(
        (coin?.data?.market_data?.current_price?.usd || 0)
        - (coin?.data?.market_data?.price_change_24h_in_currency?.usd || 0),
      );
    }

    const _currentPrice = Number(currentPrice || 0);
    const _diff = _currentPrice - _startData;

    return {
      currentPrice,
      startPrice: renderNumber(_startData),
      priceChangePercentage24h: Number((_diff / _startData) * 100).toFixed(2),
    };
  }, [coin]);

  const isGrowing = useMemo(() => numbersData.priceChangePercentage24h > 0, [numbersData]);

  const onCloseModal = () => {
    setOpenMemeConfirmModal();
  };

  return (
    <Modal
      title={chosenMemeCoin ? 'Picks' : `Pick ${openMemeConfirmModal?.name}`}
      open={openMemeConfirmModal}
      handleClose={onCloseModal}
      width="700px"
    >
      <Container className="meme-coins-page-modal">
        <div className="max-width-cont">
          <div className="flex gap-1 justify-center flex-1 mb-2 meme-coins">
            {loadingCoins ? (
              <ComponentLoader />
            ) : (
              <div className="coin-info meme-coin-data flex gap-1 justify-between flex-1">
                <div className="flex gap-1">
                  <div className="flex flex-column">
                    <img alt={`${coin.name}`} src={coin.data?.image?.large || coin.image} />
                  </div>

                  <div className="flex flex-column">
                    <div className="data-cont mb-2 flex-column">
                      <span className="value">
                        <span className="dollar">$</span>
                        {numbersData.startPrice}
                      </span>
                      <span className="label">Start Price:</span>
                    </div>
                    <div className="data-cont mb-2 flex-column">
                      <span className="value">
                        <span className="dollar">$</span>
                        {numbersData.currentPrice}
                      </span>
                      <span className="label">Current Price:</span>
                    </div>

                    <div className="flex">
                      <div className="card-chip">
                        <div className="flex flex-column mr-1">
                          <span>
                            {numbersData.priceChangePercentage24h}
                            %
                          </span>
                        </div>
                        <div className="flex flex-column">
                          {isGrowing ? (
                            <div className="chip-bubble">
                              <div className="flex justify-center">
                                <ArrowUpwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                              </div>
                            </div>
                          ) : (
                            <div className="chip-bubble not-growing-bubble">
                              <div className="flex justify-center">
                                <ArrowDownwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="meme-game-multipliers-cont flex bordered mt-4">
                    {renderCont({
                      type: 'Percentage',
                      label: 'MG Coins',
                      value: renderNumber(todayGame?.price),
                      color: '#DAAB74',
                    })}
                    <div className="flex flex-column multiplication-cont">
                      <Icon name="GameEqualSign" color="#DAAB74" width={10} height={10} />
                    </div>

                    {renderCont({
                      type: 'lastPoints',
                      label: 'Potential Tokens', // changed to points
                      value: renderNumber((todayGame?.price || 0) * (todayGame.maxMultiplier || 1)),
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          {!chosenMemeCoin && !loadingCoins ? (
            <div className="flex flex-column">
              {cannotVote ? (
                <Typography component="p" sx={{ flexGrow: 1 }}>
                  Not enough MG Coins Available!
                </Typography>
              ) : (
                <>
                </>
              )}
            </div>
          ) : null}

          <div className="modal-footer">
            {chosenMemeCoin || cannotVote ? null : (
              <Button
                onClick={() => {
                  if (loadingCoins) {
                    return;
                  }
                  onVoteMemeCoin(todayGame?.price);
                }}
                disabled={!todayGame?.price}
              >
                Pick Winner
              </Button>
            )}

            <Button onClick={onCloseModal} bordered className="btn-link">
              Cancel
            </Button>
          </div>
        </div>
      </Container>

    </Modal>
  );
}
