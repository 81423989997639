/* eslint-disable max-len */
import dayjs from 'dayjs';
import { Grid, Typography } from '@mui/material';
import CoinComponent from './CoinComponent';
import { renderNumber } from '../../utils/string';
import RedeemButton from '../RedeemButton';

const GameMatch = ({ game, userTokens }) => {
  const launchDate = dayjs(process.env.REACT_APP_REDEMPTION_START_DATE);
  const gameEndDate = dayjs(game.endDate);

  const hideRedeemButton = launchDate >= gameEndDate;

  const coin1Winner = game.scheduleGameId.coin1 === game.scheduleGameId.winningCoin;
  const coin2Winner = game.scheduleGameId.coin2 === game.scheduleGameId.winningCoin;

  const coin1Picked = game.scheduleGameId.coin1 === game.memeCoinsId;
  const coin2Picked = game.scheduleGameId.coin2 === game.memeCoinsId;

  const coin1CanRedeem = coin1Winner && coin1Picked;
  const coin2CanRedeem = coin2Winner && coin2Picked;

  const leaderboardRank = game?.rankingPercentage || 0;

  const tokenReceived = (game?.loss || game?.draw) ? game?.coins || 0 : game?.token || 0;

  const locked = game?.locked;
  const myPercentage = locked ? game?.lockChange || 0 : game?.change || 0;

  return (
    <Grid
      maxWidth="100%"
      size="xsm"
      item
      sx={{
        mb: { lg: '8px' }, padding: { xs: 2, lg: 4 },
      }}
      className="game-container"
      display="flex"
      flexDirection="column"
    >
      <Grid
        maxWidth="100%"
        size="xsm"
        className="game-container-header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0"
      >
        <Typography sx={{
          fontSize: { xs: '16px' },
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          {renderNumber(game?.scheduleGameId.price)}
          {' Coin Game'}
        </Typography>

        <div className="info">
          {game?.loss ? (
            <Typography
              sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
              className="text-number"
            >
              <span className="text-red">
                GAME LOST
              </span>
            </Typography>

          ) : (
            <Typography
              sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
              className="text-number"
            >
              <span>Leaderboard Rank:</span>
              <span className="text-gold">
                {`TOP ${parseInt(leaderboardRank, 10)}%`}
              </span>
            </Typography>
          )}
          <Typography
            sx={{ fontSize: { xs: '10px' } }}
            className="text-number"
          >
            <span>
              {(game?.loss || game?.draw) ? 'Coins Refunded' : 'Tokens Received'}
            </span>
            <span className="text-gold">
              {renderNumber(tokenReceived)}
            </span>
          </Typography>
          {(coin1CanRedeem || coin2CanRedeem) && (
            <RedeemButton
              totalTokens={userTokens}
              gameId={game.scheduleGameId.id}
              coin={coin1CanRedeem ? game.memeCoins.coinOne : game.memeCoins.coinTwo}
              buttonText={userTokens > 2000 ? 'Redeem 2,000' : null}
              bordered
            />
          )}
        </div>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="divider" />
      </Grid>
      <div className="game-container-body">
        <div className={`games${hideRedeemButton ? ' hide-redeem' : ''}`}>
          <CoinComponent
            coin={game.memeCoins.coinOne}
            coinData={game.scheduleGameId.coin1Data}
            isWinner={coin1Winner}
            myPick={coin1Picked}
            tokens={userTokens}
            gameId={game.scheduleGameId.id}
            leaderboardRank={leaderboardRank}
            tokenReceived={tokenReceived}
            percentageChange={myPercentage.toFixed(2)}
          />
          <CoinComponent
            coin={game.memeCoins.coinTwo}
            coinData={game.scheduleGameId.coin2Data}
            isWinner={coin2Winner}
            myPick={coin2Picked}
            tokens={userTokens}
            gameId={game.scheduleGameId.id}
            leaderboardRank={leaderboardRank}
            tokenReceived={tokenReceived}
            percentageChange={myPercentage.toFixed(2)}
          />
        </div>
      </div>
    </Grid>
  );
};

export default GameMatch;
