/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import CardChip from '../CardChip';
import CoinsChart from '../CoinsChart';
import { renderNumber } from '../../../../../utils/number';

export default function OthersCont({
  game, chosenMemeCoin, coins, isYesterday,
  percentageChanged,
  isTop10, isMobile,
}) {
  const leaderboardRank = !isYesterday ? game?.statistics?.rankingPercentage : game?.rankingPercentage;
  let tokensEarned = chosenMemeCoin?.coins || chosenMemeCoin?.token;
  const isWinner = chosenMemeCoin?.memeCoinsId === game?.scheduleGameId?.winningCoin;
  let label = (isTop10 && isWinner) ? 'Tokens Earned' : 'Coins Refunded';

  if (isYesterday && !tokensEarned) {
    label = 'Potential Tokens';
    tokensEarned = game?.scheduleGameId?.price * 10;
  } else if (!isYesterday && !tokensEarned) {
    label = 'Potential Tokens';
  }

  const potentialTokens = game.price * game.maxMultiplier;

  return (
    <div className="today-extra flex flex-column p-12">

      <Box sx={{
        display: 'flex',
        flexDirection: {
          xl: 'column',
          lg: 'column',
          md: 'column',
          sm: 'row',
          xs: 'row',
        },
      }}
      >
        <div className="potential-points mb-12">
          <div className="left">
            <span>
              {label}
            </span>
          </div>
          <div className="right">
            <span>
              {isYesterday ? renderNumber(tokensEarned) : renderNumber(potentialTokens)}
            </span>
          </div>
        </div>

        <CoinsChart isYesterday={isYesterday} game={game} coins={coins} />
      </Box>

      {(chosenMemeCoin && !isYesterday) || (isWinner && isYesterday) ? (
        <div className="data">
          {isMobile && (
            <div className="row-item" style={{ padding: '5px 0' }}>
              <span>{label}</span>
              <span className="primary-color">
                {renderNumber(chosenMemeCoin && !isYesterday ? potentialTokens : tokensEarned)}
              </span>
            </div>
          )}
          <div className="row-item" style={isMobile ? { padding: '5px 0' } : {}}>
            <span>My % Change:</span>
            <span className="primary-color">
              {`${renderNumber(percentageChanged.toFixed(2))}%`}
            </span>
          </div>
          <div className="row-item" style={isMobile ? { padding: '5px 0' } : {}}>
            <span>Leaderboard Rank:</span>
            <div className="meme-coin-data">
              <div className="data-cont">
                <div className="chip">
                  <CardChip isGrowing={leaderboardRank <= 10} noIcon={leaderboardRank > 10} className="with-border">
                    {`${parseInt(leaderboardRank, 10)}%`}
                  </CardChip>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
