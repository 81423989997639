import { useContext, useEffect, useState } from 'react';
import { Grid, Slider, Typography } from '@mui/material';
import { AppContext } from '../../../utils/store/app';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { renderNumber } from '../../../utils/string';
import { RedeemTokenContext } from '../context';
import RadioGroup from '../../../components/Inputs/RadioGroup';

const redeemIncrements = 1000;

const RedeemCoinPackModal = () => {
  const {
    loadedWalletData,
    walletData,
    handleRedeemCoinPackSubmit,
  } = useContext(RedeemTokenContext);
  
  const { passTypes, packages } = useContext(AppContext);

  const filteredPassTypes = [
    ...passTypes.filter((type) => type.price === 100),
    ...packages,
  ];

  const [redeemAmount, setRedeemAmount] = useState(redeemIncrements);
  const [minRedeemAmount, setMinRedeemAmount] = useState(redeemIncrements);

  const [selectedPassTypeId, setSelectedPassTypeId] = useState(null);
  const [selectedIsBundle, setSelectedIsBundle] = useState(false);
  
  const [openModal, setOpenModal] = useState(false);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const handleSelectedPassTypeChange = (id) => {
    setSelectedPassTypeId(id);

    const selected = filteredPassTypes.find((pt) => pt.id === id);

    const newPrice = selected.price || selected.Price;

    setMinRedeemAmount(newPrice * 10);
    setRedeemAmount(newPrice * 10);

    setSelectedIsBundle(selected.Price > 0);
  };

  const handleOpenPopUpModal = () => {
    setPopupIsOpen(true);
  };

  const handleClosePopUpModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPopupIsOpen(false);
  };

  useEffect(() => {
    handleCloseModal();
  }, [walletData]);

  useEffect(() => {
    handleSelectedPassTypeChange(filteredPassTypes[0].id);
  }, [openModal]);

  return (
    <>
      <Grid
        xs={12}
        item
        display="flex"
        justifyContent="center"
        marginTop="32px"
      >
        <Button
          onClick={handleOpenPopUpModal}
          size="sm"
          disabled={!selectedPassTypeId}
          bordered={walletData.token < redeemIncrements}
        >
          <span className="no-wrap">Redeem Coin Packs</span>
        </Button>
        <Modal
          title="Redeem"
          width="550px"
          open={popupIsOpen}
          handleClose={() => { handleClosePopUpModal(); }}
        >
          <p className="mb-8 text-center">
            Redemptions of your MG Tokens can be used to buy new gamer packs and Meme Coins on the 
            Solana blockchain! Packs start at 1,000 Tokens and Meme Coins start at 2,000 Tokens. 
            Redeem for any Meme Coin you have won with in any of your previous games. We will send 
            you $200 worth of that selected Meme Coin within 5 business days, the price of the Meme 
            Coin at the time it is processed. Meme Coins are transferred to the Solana 
            wallet you have set up in your Gamer Wallet.
          </p>
          <Button onClick={handleClosePopUpModal} bordered>Close to Continue</Button>
        </Modal>
      </Grid>
      {selectedPassTypeId && (
        <Modal
          width="600px"
          title="Redeem Coin Packs"
          open={openModal && !!selectedPassTypeId && loadedWalletData}
          handleClose={handleCloseModal}
        >
          <Typography
            className="text-center"
            sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 2 }}
          >
            {'Redeem can only be performed '}
            <br />
            {`in ${renderNumber(redeemIncrements)} tokens increments`}
          </Typography>

          {walletData.token >= redeemIncrements ? (
            <>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                paddingTop="12px"
                sx={{ paddingX: 4 }}
              >
                <RadioGroup
                  onChange={(e) => {
                    handleSelectedPassTypeChange(e);
                  }}
                  value={selectedPassTypeId}
                  defaultValue={selectedPassTypeId || filteredPassTypes[0].id}
                  setErrors={() => {}}
                  name="selectedPassTypeId"
                  options={filteredPassTypes.map((p) => ({
                    label: `${p.name} ($${p?.price || p?.Price}) ${
                      p?.price
                        ? `(${Math.floor(redeemAmount / (p.price * 10))} packs)`
                        : `(${renderNumber(p.Price * 10)} tokens)`
                    }`,
                    value: p.id,
                  }))}
                />
              </Grid>
              <Grid item sx={{ marginTop: 2, paddingX: 4 }}>
                <Slider
                  min={minRedeemAmount}
                  max={
                    Math.floor(walletData.token / minRedeemAmount) * minRedeemAmount
                  }
                  value={redeemAmount}
                  step={minRedeemAmount}
                  getAriaValueText={() => redeemAmount}
                  valueLabelDisplay="auto"
                  onChange={(e, v) => {
                    setRedeemAmount(v);
                  }}
                  disabled={walletData.token < minRedeemAmount || selectedIsBundle}
                />
                <Button
                  onClick={() => {
                    if (walletData.token >= minRedeemAmount) {
                      handleRedeemCoinPackSubmit({
                        redeemAmount,
                        passTypeId: selectedPassTypeId,
                      });
                    }
                  }}
                  className="mt-3"
                  disabled={walletData.token < minRedeemAmount}
                >
                  <span className="no-wrap">
                    Redeem (
                    {renderNumber(redeemAmount)}
                    )
                  </span>
                </Button>
              </Grid>
            </>
          ) : (
            <Typography
              className="text-center"
              sx={{ fontSize: '16px', fontWeight: 600, marginY: 4 }}
              color="#fe5656"
            >
              {`You need at least ${renderNumber(
                redeemIncrements,
              )} tokens to Redeem coin packs, keep playing.`}
            </Typography>
          )}
        </Modal>
      )}
    </>
  );
};

export default RedeemCoinPackModal;
