import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { validateAll } from '../../../../components/Form/config';
import { podcastFormValidationConfig } from '../config';

const PAGE_SIZE = 25;

const useAdminPodcast = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const {
    authGet, authPost, authDelete, authPut, 
  } = useApi();
  const [podcast, setPodcast] = useState(null);
  const [podcastDelete, setPodcastDelete] = useState(null);
  const [podcastFormErrors, setPodcastFormErrors] = useState(null);
  const [podcastsData, setPodcastsData] = useState([]);
  const [podcastsDataLoaded, setPodcastsDataLoaded] = useState(false);
  const [podcastFormLoading, setPodcastFormLoading] = useState(false);
  const [paginationData, setPaginationData] = useState();

  const getPodcastsData = useCallback(
    async ({ page = 1 }) => {
      try {
        setPodcastsDataLoaded(false);
        const response = await authGet('/podcast', {
          params: { page, perPage: PAGE_SIZE },
        });
        setPodcastsData(response.data || []);

        setPaginationData({
          totalPodcasts: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
        toast.error('Error fetching podcast Data');
      }
      setPodcastsDataLoaded(true);
    },
    [authGet],
  );

  const handleOpenModalToAdd = useCallback(
    async () => {
      setPodcast({
        title: '',
        description: '',
        published: true,
        releaseDate: new Date(),
      });
    },
    [setPodcast],
  );

  const handleOpenModalToEdit = useCallback(
    async (podcastInfo) => {
      setPodcast(podcastInfo);
    },
    [setPodcast],
  );

  const handleCloseModal = useCallback(
    async () => {
      setPodcast(null);
    },
    [setPodcast],
  );

  const handleSubmitFormModal = useCallback(
    async (data) => {
      setPodcastFormLoading(true);
      const isEditing = data?.id?.length > 0;

      setPodcastFormErrors(null);
      const checkForm = validateAll(data, podcastFormValidationConfig);

      // If editing, don't validate the file input
      if (isEditing) {
        delete checkForm.file;
      }
      
      if (checkForm && Object.keys(checkForm).length > 0) { 
        setPodcastFormErrors(checkForm);
        return;
      }

      try {
        const response = isEditing
          ? await authPut(`/podcast/${data.id}`, { data })
          : await authPost('/podcast', { data });
        
        console.log(response);

        const successMessage = isEditing
          ? 'Podcast edited successfully.'
          : 'New Podcast has been created.';
        
        toast.success(successMessage);
        handleCloseModal();
        getPodcastsData({});
      } catch (err) {
        console.log(err);
      }
      setPodcastFormLoading(false);
    },
    [authPost, authPut],
  );
  
  const handleOpenDeleteModal = useCallback(
    async (podcastInfo) => {
      setPodcastDelete(podcastInfo);
    },
    [setPodcastDelete],
  );
  
  const handleCloseDeleteModal = useCallback(
    async () => {
      setPodcastDelete(null);
    },
    [setPodcastDelete],
  );

  const handleSubmitDeleteModal = useCallback(
    async () => {
      try {
        const response = await authDelete(`podcast/${podcastDelete.id}`);

        console.log(response);

        toast.success('Deleted Successfully.');

        handleCloseDeleteModal();
        getPodcastsData({});
      } catch (err) {
        console.log(err);
      }
    },
    [authDelete, podcastDelete],
  );

  useEffect(() => {
    getPodcastsData({});
  }, []);

  return {
    t,
    user,

    podcast,
    podcastsData,
    podcastsDataLoaded,
    podcastFormLoading,
    
    getPodcastsData,
    handleOpenModalToAdd,
    handleOpenModalToEdit,
    handleCloseModal,
    handleSubmitFormModal,

    podcastDelete,

    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleSubmitDeleteModal,

    podcastFormErrors,
    setPodcastFormErrors,

    paginationData,
  };
};

export default useAdminPodcast;
