/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
import { useTranslation } from 'react-i18next';
import {
  useContext, useState, useEffect, useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  affiliateColumnsConfig,
  rankColumnsConfig, salesColumnsConfig, newsTabs, dummyNews, aiExperts,
} from '../config';
import { AuthenticationContext } from '../../../utils/store/authentication';
import useApi, { getGeckoApiConfig } from '../../../utils/api';
import { tabTypes, usePickPoints } from './helper';

const PAGE_SIZE = 10;

const useHomeApi = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabTypes.TODAY);
  const { user, socket } = useContext(AuthenticationContext);
  const [rank, setRank] = useState([]);
  const [sales, setSales] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsIndex, setEventsIndex] = useState(0);
  const [loadingUpdates, setLoadingUpdates] = useState(false);
  const [updatesData, setUpdatesData] = useState([]);
  const [updatesPage, setUpdatesPage] = useState(0);
  const [updatesTotal, setUpdatesTotal] = useState(0);
  const [salesLoading, setSalesLoading] = useState(false);
  const [volumesLoading, setVolumesLoading] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState(0);
  const [coopWallet, setCoopWallet] = useState(0);
  const { authGet, authPost } = useApi();
  const [eventsLoading, setEventsLoading] = useState(false);
  const navigate = useNavigate();
  const [gamerWallet, setGamerWallet] = useState();

  const [leaderboardRank, setLeaderboardRank] = useState();
  const [tokensGainedThisWeek, setTokensGainedThisWeek] = useState();
  const [personalVolume, setPersonalVolume] = useState(0);
  const [gamersVolume, setGamersVolume] = useState(0);
  const [qualificationPercent, setQualificationPercent] = useState(0);
  const [loadingVolumes, setLoadingVolumes] = useState(false);
  const [activatedPassesToDisplay, setActivatedPassesToDisplay] = useState([]);

  const points = useMemo(() => user?.points || { total: 0, projectedPointsForToday: 0, pointsYesterdayWMultipliers: 0 }, [user]);

  const fetchActivatedPasses = async () => {
    try {
      const res = await authGet('/passes/display');
      setActivatedPassesToDisplay(res.pass);

      if (res.message) {
        // do nothing
      } else {
        setActivatedPassesToDisplay(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeaderboardRank = async () => {
    try {
      // const resp = await authGet('/gaming-points/currentUserRank');
      // setLeaderboardRank(Number(resp?.currentUser?.leaderboardRank));
      setLeaderboardRank(0);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTokensGainedThisWeek = async () => {
    try {
      const resp = await authGet('/gaming-points/find-week');
      setTokensGainedThisWeek(resp?.tokens);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPersonalVolume = async () => {
    try {
      const resp = await authGet('/payments/personal-volume');
      setPersonalVolume(resp?.pv);
      setGamersVolume(resp?.gamerVol);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchQualificationPercent = async () => {
    setLoadingVolumes(true);
    try {
      const resp = await authGet('/payments/qualification');
      setQualificationPercent(resp);
    } catch (err) {
      console.log(err);
    }
    setLoadingVolumes(false);
  };

  useEffect(() => {
    if (user.isProduct) {
      fetchLeaderboardRank();
      fetchTokensGainedThisWeek();
    }
    if (user.isAffiliate) {
      fetchPersonalVolume();
      fetchQualificationPercent();
    }
    fetchActivatedPasses();
  }, [user]);

  // #region Rank Progression & Volume
  useEffect(() => {
    if (user.isAffiliate) {
      const _rank = user?.rank ? Number(user.rank) : 0 || 0;
      setRank([
        {
          id: 0,
          name: 'Current Rank',
          rank: `${_rank} Star`,
        },
        {
          id: 1,
          name: 'Next Rank',
          rank: `${_rank + 1} Star`,
        },
      ]);
    }
  }, [user]);

  const rankColumns = useMemo(() => rankColumnsConfig(t), [t]);

  const fetchSales = async () => {
    setSalesLoading(true);
    try {
      const _sort = 'orderBy[dateJoined]';
      const resp = await authGet('/genealogy/users', {
        params: {
          page: 1, perPage: 10, [_sort]: '-1', isAffiliate: true, isProduct: true,
        },
      });

      setSales(resp.data);
      setSalesLoading(false);
    } catch (err) {
      console.log(err);
      setSalesLoading(false);
    }
  };

  const fetchVolume = async () => {
    setVolumesLoading(true);
    try {
      const res = await authGet('/dashboard/volumes');
      setVolumes([res]);
      setVolumesLoading(false);
    } catch (err) {
      console.log(err);
      setVolumesLoading(false);
    }
  };

  useEffect(() => {
    if (user.isAffiliate) {
      fetchVolume();
      fetchSales();
    }
  }, [user]);

  const salesColumns = useMemo(() => salesColumnsConfig(t), [t]);

  // #endregion

  // #region wallets
  // const formatWalletData = (_data) => {
  //   const _default = _data?.find((i) => i.type === 'default');
  //   const _investment = _data?.find((i) => i.type === 'investment');

  //   setDefaultWallet(_default || {});
  //   setCoopWallet(_investment || {});
  // };

  // const fetchWalletData = async () => {
  //   try {
  //     /// get whatever wallet data is needed
  //     const _data = await authGet('/wallets');
  //     formatWalletData(_data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchWalletData();
  // }, []);

  // #endregion

  // #region Events

  // const getEvents = async () => {
  //   setEventsLoading(true);
  //   try {
  //     const res = await authGet('/events');
  //     setEvents(res.map((i, index) => ({
  //       id: index,
  //       title: i.zapier_display_title,
  //       description: i.body,
  //       date: i.created_at,
  //     })));
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setEventsLoading(false);
  // };

  const eventChange = (_nr) => {
    if (_nr === events.length) {
      setEventsIndex(0);
    } else if (_nr === -1) {
      setEventsIndex(events.length - 1);
    } else {
      setEventsIndex(_nr);
    }
  };

  // useEffect(() => {
  //   getEvents();
  // }, []);

  // #endregion

  // #region Affiliate Updates
  const fetchUpdates = () => {
    try {
      setLoadingUpdates(true);
      setUpdatesData([]);
      setUpdatesPage(0);
      setUpdatesTotal(0);
    } catch (err) {
      console.log(err);
    }
  };

  const affiliateUpdatesColumns = useMemo(() => affiliateColumnsConfig(t), [t]);
  const totalPages = useMemo(() => Math.ceil(updatesTotal / PAGE_SIZE), [updatesTotal]);

  // #endregion

  // #region circle

  const onTrecGlobalClick = async () => {
    navigate('https://trec-global.circle.so/home');
  };

  // #endregion

  // #region AI

  const [clickedAIButton, setClickedAIButton] = useState(0);

  const [aiQuestion, setAiQuestion] = useState('');
  const [aiAnswer, setAiAnswer] = useState('');
  const [aiAnswerLoading, setAiAnswerLoading] = useState(false);
  const [aiConversation, setAiConversation] = useState([]);

  // const openai = new OpenAI({
  // apiKey: process.env.REACT_APP_CHATGPT_KEY, // This is the default and can be omitted
  // dangerouslyAllowBrowser: true,
  // apiKey: process.env.REACT_APP_CHATGPT_KEY,
  // });

  const [askAIModal, setAskAIModal] = useState(false);
  const handleAskAIModalClose = () => {
    setAskAIModal(false);
  };

  const askAiSubmit = async () => {
    if (!aiQuestion) {
      return;
    }

    setAiAnswerLoading(true);
    const answerTime = dayjs();
    const _id = aiConversation.length;
    const _newConversation = [...aiConversation, {
      id: _id,
      q: aiQuestion,
      a: '',
      state: 'loading',
      qTime: answerTime,
      aTime: dayjs(),
    }];

    setAiConversation(_newConversation);

    try {
      const res = await authPost('/openai/answer', { data: { question: aiQuestion } });
      setAiAnswer(res.answer);
      setAiConversation(_newConversation.map((i) => {
        if (i.id === _id) {
          return {
            ...i,
            a: res.answer,
            aTime: dayjs(),
            state: 'done',

          };
        }
        return i;
      }));

      setAiQuestion('');
      setAiAnswerLoading(false);
    } catch (error) {
      setAiAnswer('Error fetching response!');
      setAiAnswerLoading(false);
      console.error('Error fetching response:', error);
    }
  };

  // #endregion

  // #region NEws

  const [cryptoTimeSpan, setCryptoTimeSpan] = useState('24H');
  const [newsTab, setNewsTab] = useState(0);
  const [newsTabTab, setNewsTabTab] = useState(0);
  // const [news, setNews] = useState(dummyNews);

  // const getNews = async () => {
  //   try {
  //     const res = await authGet('/dashboard/news');

  //     setNews({
  //       0: res?.crypto?.data || [],
  //       1: res?.technology?.data || [],
  //       2: res?.realEstate?.data || [],
  //     });
  //     // setNews(dummyNews);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getNews();
  // }, []);

  const changeNewsTab = (type) => {
    if (type === 'prev') {
      if (newsTab === 0) {
        setNewsTab(newsTabs.length - 1);
      } else {
        setNewsTab(newsTab - 1);
      }
    }
    if (type === 'next') {
      if (newsTab === newsTabs.length - 1) {
        setNewsTab(0);
      } else {
        setNewsTab(newsTab + 1);
      }
    }
    setNewsTabTab(0);
  };

  const changeNewsTabTab = (type) => {
    if (type === 'prev') {
      if (newsTabTab === 0) {
        setNewsTabTab(dummyNews[newsTab].length - 1);
      } else {
        setNewsTabTab(newsTabTab - 1);
      }
    }
    if (type === 'next') {
      if (newsTabTab === dummyNews[newsTab].length - 1) {
        setNewsTabTab(0);
      } else {
        setNewsTabTab(newsTabTab + 1);
      }
    }
  };
  // #endregion

  // #region experts

  const [expert, setExpert] = useState(0);

  const changeExpert = (type) => {
    if (type === 'prev') {
      if (expert === 0) {
        setExpert(aiExperts.length - 1);
      } else {
        setExpert(expert - 1);
      }
    }
    if (type === 'next') {
      if (expert === aiExperts.length - 1) {
        setExpert(0);
      } else {
        setExpert(expert + 1);
      }
    }
    // setExpert(0);
  };

  // #endregion

  // #region meme coins pool

  const [memeCoinsList, setMemeCoinsList] = useState([]);
  const [chosenMemeCoin, setChosenMemeCoin] = useState([]);
  const [chosenYesterdayMemeCoin, setYesterdayChosenMemeCoin] = useState([]);
  const [loadingCoins, setLoadingCoins] = useState(true);
  const [gamingFunds, setGamingFunds] = useState(0);
  const [tokens, setTokens] = useState(0);
  const [gamingFundsLoading, setGamingFundsLoading] = useState(true);
  const [openMemeConfirmModal, setOpenMemeConfirmModal] = useState();
  const [yesterdayMemeCoins, setYesterdayMemeCoins] = useState([]);
  const [todayGame, setTodayGame] = useState([]);
  const [streakMultiplier, setStreakMultiplier] = useState(1);
  const { getStreakMultiplier } = usePickPoints();

  const getGeckoData = async (coin) => {
    try {
      // const search = await authGet(`/search?query=${coin.name}`, getGeckoApiConfig());
      // const geckoCoin = (search?.coins || [])[0];
      // eslint-disable-next-line no-await-in-loop
      const geckoRes = await authGet(`/coins/${coin?.apiId}?localization=false&tickers=false&market_data=true&community_data=true&developer_data=false&sparkline=true`, getGeckoApiConfig());
      return geckoRes;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const getChosenMemecoin = async (gId) => {
    try {
      const res = await authGet(`/schedule-game/vote-history/${gId}`);
      setChosenMemeCoin((prev) => ([
        ...prev.filter((p) => p.gameId !== gId),
        (res ? {
          ...res, id: res.memeCoinsId, mgCoins: res.mgCoins, gameId: gId,
        } : null),
      ]).filter((v) => !!v));
    } catch (err) {
      console.log(err);
      setChosenMemeCoin(null);
    }
  };

  const getYesterdayMemecoin = async (gId) => {
    try {
      const res = await authGet(`/schedule-game/vote-history/${gId}`);
      setYesterdayChosenMemeCoin((prev) => ([
        ...prev.filter((p) => p.gameId !== gId),
        (res ? {
          ...res, id: res.memeCoinsId, mgCoins: res.mgCoins, gameId: gId,
        } : null),
      ]).filter((v) => !!v));
      return res;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getMemeCoin = async (today) => {
    try {
      setLoadingCoins(true);
      const unsortedGames = await authGet('/schedule-game/today');
      // const games = Array.isArray(unsortedGames) ? unsortedGames.sort((a, b) => b.price - a.price) : [unsortedGames];

      const todayCoins = [];

      for (let j = 0; j < unsortedGames.length; j += 1) {
        const tmp = unsortedGames[j];
        if (tmp?.id && today) {
          getChosenMemecoin(tmp ? tmp.id : '');
        }
        setTodayGame(unsortedGames);

        try {
          const res = await getStreakMultiplier({ todayGame: tmp });
          setStreakMultiplier(res);
        } catch (err) {
          console.log(err);
        }

        const res = [{
          ...tmp.coinOne,
          ...tmp.coin1Data,
        }, {
          ...tmp.coinTwo,
          ...tmp.coin2Data,
        }];

        const _res = [];
        for (let i = 0; i < res.length; i += 1) {
          const coin = res[i];
          const otherCoin = i === 0 ? res[i + 1] : res[0];
          const geckoRes = await getGeckoData(coin);
          _res.push({
            ...coin, data: geckoRes, winner: coin.votes > otherCoin.votes, loser: coin.votes < otherCoin.votes, draw: coin.votes === otherCoin.votes, solanaId: geckoRes?.platforms?.solana, description: geckoRes?.description?.en, categories: geckoRes?.categories, communityData: geckoRes?.community_data,
          });
        }
        todayCoins.push({ gameId: tmp.id, coins: _res });
      }
      setMemeCoinsList(todayCoins);
    } catch (err) {
      console.log(err);
    }
    setLoadingCoins(false);
  };

  const getYesterdaysMemeCoin = async () => {
    try {
      const yGames = await authGet('/schedule-game/yesterday');
      // const games = Array.isArray(data) ? data.sort((a, b) => b.price - a.price) : [data];
      const yesterdayCoins = [];
      
      for (let j = 0; j < yGames.length; j += 1) {
        const tmp = yGames[j];

        if (tmp.scheduleGameId.id) {
          getYesterdayMemecoin(tmp ? tmp.scheduleGameId.id : '');
        }

        const res = [{ ...tmp.memeCoins.coinOne, ...tmp.scheduleGameId.coin1Data }, { ...tmp.memeCoins.coinTwo, ...tmp.scheduleGameId.coin2Data }];
        const _res = [];
        const gameId = tmp.scheduleGameId.id;
        const winnerId = tmp?.scheduleGameId?.winningCoin;
        for (let i = 0; i < res.length; i += 1) {
          const coin = res[i];
          // eslint-disable-next-line no-await-in-loop
          // const search = await authGet(`/search?query=${coin.name}`, getGeckoApiConfig());
          // const geckoCoin = (search?.coins || [])[0];
          // eslint-disable-next-line no-await-in-loop
          const geckoRes = await authGet(`/coins/${coin?.apiId}?localization=false&tickers=false&market_data=true&community_data=true&developer_data=false&sparkline=true`, getGeckoApiConfig());

          _res.push({
            ...coin,
            data: geckoRes,
            ...tmp.scheduleGameId[`coin${i + 1}Data`],
            statistics: tmp.statistics,
            winner: winnerId ? winnerId === tmp.scheduleGameId[`coin${i + 1}`] : false,
            loser: winnerId ? winnerId !== tmp.scheduleGameId[`coin${i + 1}`] : false,
            draw: !winnerId,
            solanaId: geckoRes?.platforms?.solana,
            description: geckoRes?.description?.en,
            categories: geckoRes?.categories,
            communityData: geckoRes?.community_data,
          });
        }
        yesterdayCoins.push({ gameId, game: tmp, coins: _res });
      }
      setYesterdayMemeCoins(yesterdayCoins);
    } catch (err) {
      console.log('_e', err);
    }
  };

  const onChooseMemeCoin = (chosen, gameId) => {
    setOpenMemeConfirmModal({ ...chosen, gameId });
  };

  const getGamingFunds = async () => {
    setGamingFundsLoading(true);

    try {
      const res = await authGet('/gamer-wallet');
      setGamingFunds(res?.coins || 0);
      setTokens(res?.token || 0);
    } catch (err) {
      console.log(err);
      setGamingFunds(0);
    }

    setGamingFundsLoading(false);
  };

  const onVoteMemeCoin = async (mgCoins) => {
    if (!mgCoins) {
      return;
    }

    try {
      setLoadingCoins(true);

      const res = await authPost('/schedule-game/vote', {
        data: {
          memeCoinsId: openMemeConfirmModal.id,
          gameId: openMemeConfirmModal.gameId,
          mgCoins,
        },
      });
      await getChosenMemecoin(todayGame.id);
      getMemeCoin(true);
      getGamingFunds();
      setOpenMemeConfirmModal(null);
      toast.success(res.message);
    } catch (err) {
      console.log(err);
      toast.error(err || '');
      setOpenMemeConfirmModal();
    }
  };

  const updateGeckoData = useCallback(async () => {
    try {
      for (let i = 0; i < todayGame.length; i += 1) {
        const {
          coinOne, coinTwo, coin1Data, coin2Data,
        } = todayGame[i];
        const g1 = await getGeckoData(coinOne);
        const g2 = await getGeckoData(coinTwo);

        setMemeCoinsList((prev) => prev.map((p) => {
          if (p.gameId === todayGame[i].id) {
            return {
              ...p,
              coins: [
                {
                  ...coinOne, ...coin1Data, data: g1, winner: coinOne.votes > coinTwo.votes, loser: coinOne.votes < coinTwo.votes, draw: coinOne.votes === coinTwo.votes, solanaId: g1?.platforms?.solana, description: g1?.description?.en, categories: g1?.categories, communityData: g1?.community_data,
                },
                {
                  ...coinTwo, ...coin2Data, data: g2, winner: coinTwo.votes > coinOne.votes, loser: coinTwo.votes < coinOne.votes, draw: coinTwo.votes === coinOne.votes, solanaId: g1?.platforms?.solana, description: g2?.description?.en, categories: g2?.categories, communityData: g2?.community_data,
                },
              ],
            };
          }

          return p;
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }, [todayGame]);

  const refreshMemeCoins = () => {
    getMemeCoin(true);
    if (user.isProduct) {
      getGamingFunds();
    }
    getYesterdaysMemeCoin();
  };

  useEffect(() => {
    if (user.isProduct) {
      refreshMemeCoins();
    }
  }, [user]);

  const userWonYesterday = useMemo(() => !!yesterdayMemeCoins.find((c) => (
    c.winner && c.id === chosenYesterdayMemeCoin?.memeCoinsId
  )), [yesterdayMemeCoins, chosenYesterdayMemeCoin]);

  // #endregion

  return {
    rank,
    rankColumns,

    sales,
    setSales,
    salesColumns,

    user,
    events,
    eventsIndex,
    setEventsIndex,
    eventChange,
    t,
    loadingUpdates,
    affiliateUpdatesColumns,
    updatesData,
    totalPages,
    updatesPage,
    fetchUpdates,
    salesLoading,
    defaultWallet,
    coopWallet,
    onTrecGlobalClick,
    eventsLoading,

    volumes,
    volumesLoading,

    clickedAIButton,
    setClickedAIButton,
    askAIModal,
    setAskAIModal,
    handleAskAIModalClose,
    setAiAnswer,
    aiAnswer,
    aiQuestion,
    setAiQuestion,
    aiAnswerLoading,
    setAiAnswerLoading,
    askAiSubmit,
    aiConversation,

    cryptoTimeSpan,
    setCryptoTimeSpan,

    newsTab,
    changeNewsTab,
    changeNewsTabTab,
    newsTabTab,
    // news,

    changeExpert,
    expert,
    aiExperts,

    onChooseMemeCoin,
    chosenMemeCoin,
    setChosenMemeCoin,
    onVoteMemeCoin,
    memeCoinsList,
    openMemeConfirmModal,
    setOpenMemeConfirmModal,
    loadingCoins,
    yesterdayMemeCoins,
    refreshMemeCoins,

    points,
    tokens,
    todayGame,
    gamingFunds,
    updateGeckoData,
    gamingFundsLoading,
    chosenYesterdayMemeCoin,
    setYesterdayChosenMemeCoin,
    streakMultiplier,
    userWonYesterday,

    gamerWallet,

    leaderboardRank,
    tokensGainedThisWeek,
    personalVolume,
    gamersVolume,
    qualificationPercent,
    activeTab,
    setActiveTab,

    loadingVolumes,
    activatedPassesToDisplay,
    socket,
  };
};

export default useHomeApi;
