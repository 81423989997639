/* eslint-disable max-len */
import { useContext } from 'react';
// import { changeLanguage } from 'i18next';
import OthersCont from '../OthersCont';
import { Context } from '../../../context';

export default function TodayOtherCoins({
  game, coins, leaderboardRank, isMobile,
}) {
  const { chosenMemeCoin: chosenMemeCoins } = useContext(Context);
  const chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === game.id);
  const isTop10 = game?.statistics?.rankingPercentage <= 10;
  const percentageChanged = game?.statistics?.change;

  return (
    <OthersCont
      game={game}
      chosenMemeCoin={chosenMemeCoin}
      coins={coins}
      isTop10={isTop10}
      percentageChanged={percentageChanged}
      leaderboardRank={leaderboardRank}
      isMobile={isMobile}
    />
  );
}
