import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

export default function ActivatedPassesModal({ data = [] }) {
  const [contentData, setContentData] = useState(data);
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setContentData(data);
  }, [data]);

  useEffect(() => {
    if (contentData?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [contentData]);
  return (
    <Modal canClose={false} background="transparent" open={open} handleClose={() => setOpen(false)}>
      <Box sx={{ maxHeight: '90vh', overflow: 'auto' }}>
        {contentData?.map((i, ii) => (
          <Box sx={{
            background: 'black',
            mb: 2,
            pt: 2,
            borderRadius: 2,
            pb: 3,

          }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setContentData(contentData?.filter((u, uu) => uu !== ii));
                }}
                sx={{
                  color: 'white',
                  fontSize: '15px',
                }}
              >
                <CloseIcon fontSize="14px" />
              </IconButton>
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <Typography sx={{
                textAlign: 'center', color: 'var(--primary-color)', fontWeight: 600, textTransform: 'uppercase',
              }}
              >
                {t('Congratulations!')}
              </Typography>
              <Typography sx={{ textAlign: 'center', padding: '0 20px' }}>
                {t(`You have activated a new Game Pack for ${i?.passType?.points || 0} MG coins. Choose a game, research the meme coins and start playing!`)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <Button
                onClick={() => {
                  setContentData(contentData?.filter((u, uu) => uu !== ii));
                }}
                size="sm"
              >
                {' '}
                Lets Go!
              </Button>
            </Box>

          </Box>
        ))}
      </Box>

    </Modal>
  );
}
