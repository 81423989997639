import LoopIcon from '@mui/icons-material/Loop';
import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import PlayPodcast from '../../../../components/PlayPodcast';
import { renderHourFromSeconds } from '../../../../utils/string';
import { PodcastContext } from '../context';

const PodcastRow = ({ podcast }) => {
  const { t } = useContext(PodcastContext);
  const [audioMetaData, setAudioMetadata] = useState(null);

  return (
    <Grid
      container
      backgroundColor="rgba(0, 0, 0, 0.12)"
      className="podcast-page-container"
    >
      <Grid item xs={12} md={4} lg={3} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Title')}
        </Typography>
        <Typography fontSize="14px">{podcast?.title}</Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={3} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Release Date')}
        </Typography>
        <Typography fontSize="14px">
          {dayjs(podcast?.releaseDate).format('MMM DD, YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} lg={2} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Duration')}
        </Typography>
        <Typography fontSize="14px">
          {audioMetaData ? (
            <>
              {audioMetaData.elapsed > 0 && (
                <span>
                  {renderHourFromSeconds(audioMetaData.elapsed)}
                  {' '}
                  /
                  {' '}
                </span>
              )}
              <span>{renderHourFromSeconds(audioMetaData.duration || 0)}</span>
            </>
          ) : (
            <LoopIcon color="primary" className="loading-icon" />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        lg={4}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-start', lg: 'flex-end' },
          paddingTop: { xs: 2, lg: 0 },
        }}
      >
        <PlayPodcast
          title="Listen to the Podcast!"
          setAudioMetadata={setAudioMetadata}
        />
      </Grid>
    </Grid>
  );
};

export default PodcastRow;
