/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
// Backend URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Google Web Client ID
export const GOOGLE_CLOUD_ID = process.env.REACT_APP_GOOGLE_CLOUD_ID;

// Sentry DNS
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;

// Default Per Page
export const PER_PAGE = 20;

// LAYOUT
export const DRAWER_WIDTH = 302;
export const APP_BAR_HEIGHT = 80;

// USER PACKAGES
export const USER_PACKAGES = {
  SILVER: 'Silver',
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
};

export const PROMO_MESSAGES = {
  finalExtension: {
    id: 0,
    name: 'Attention',
    message: 'FINAL EXTENSION: Each Ambassador that becomes a paid Beta Gamer by 7:59pm ET, July 3 2024 will automatically receive $1 million dollar Team Volume bonus added to their Team 1};',
  },
  mvpKickoff: {
    id: 1,
    name: 'Attention',
    message: 'Million Dollar Promo: Each Ambassador that becomes a gamer by 7:59pm ET, July 10 ET will automatically receive a $1 million dollar Team Volume bonus added to their team 1 for 30 days.',
  },
  mvpKickoff2: {
    id: 2,
    name: 'Attention',
    message: `Million Dollar Promotion FINAL EXTENSION: Each Ambassador that becomes a gamer by 7:59pm ET, September 30th 2024 will automatically receive a $1 million dollar Team Volume bonus added to their team 1 for 30 days.
    `,
    message1:
      <span>
        Join the daily Opportunity Zoom weekdays at 1pm ET / 5pm GMT to learn more about our Ambassador program:
        {' '}
        <a style={{ color: 'var(--primary-color)', cursor: 'pointer' }} href=" http://memegames.live"> http://memegames.live</a>
      </span>,

  },
  mvpKickoff3: {
    id: 3,
    name: 'Attention',
    message: '2x MG Coin Promo: All MG Coin packs and bundles purchased by October 31, 2024 will receive 2x MG Coins when activated.',
  },
};
