import useAdminReportApi from './api';

const useAdminReportHook = () => {
  const {
    t,
    user,
    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,
    fetchSalesData,
    salesData,
    loadedSalesData,
    salesColumnsConfiguration,
    usageDataColumnsConfiguration,
    downloadingCsv,
    downloadUsageReportCsv,
  } = useAdminReportApi();

  return {
    t,
    user,
    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,
    fetchSalesData,
    salesData,
    loadedSalesData,
    salesColumnsConfiguration,
    usageDataColumnsConfiguration,
    downloadingCsv,
    downloadUsageReportCsv,
  };
};

export default useAdminReportHook;
