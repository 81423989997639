import { useContext } from 'react';
import OthersCont from '../OthersCont';
import { Context } from '../../../context';

export default function YesterdayOtherCoins({ game = {}, coins, isMobile }) {
  const leaderRank = game.rankingPercentage;
  const { chosenYesterdayMemeCoin: chosenYesterdayMemeCoins } = useContext(Context);
  const chosenMemeCoin = chosenYesterdayMemeCoins.find((c) => c.gameId === game.scheduleGameId?.id);
  const isTop10 = game?.rankingPercentage <= 10;
  const potentialPoints = game?.score || 0;
  const percentageChanged = game?.change || 0;

  return (
    <OthersCont
      game={game}
      isYesterday
      coins={coins}
      isTop10={isTop10}
      chosenMemeCoin={chosenMemeCoin}
      leaderboardRank={leaderRank}
      potentialPoints={potentialPoints}
      percentageChanged={percentageChanged}
      isMobile={isMobile}
    />
  );
}
