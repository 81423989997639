/* eslint-disable no-restricted-globals */
export const renderNumber = (val) => {
  if (typeof val === 'number') {
    return val.toLocaleString('en-us');
  }
  if (!isNaN(val)) {
    return Number(val).toLocaleString('en-us');
  } 
  return 0;
};
export default null;
