/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',

  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t) => [
  {
    field: 'dirAction',
    headerName: t('Incoming / Outgoing'),
    width: 180,
    ...defaultConfig,
    renderCell: ({ value }) => (value === 'Sent' ? 'Outgoing' : value === 'Received' ? 'Incoming' : value),
  },
  {
    field: 'key',
    headerName: t('Pass'),
    width: 280,
    ...defaultConfig,
    renderCell: ({ row }) => row?.passType?.name,
  },
  {
    field: 'from',
    headerName: t('From'),
    width: 280,
    ...defaultConfig,
    renderCell: ({ value }) => `${value?.name} (${value?.username})`,
  },
  {
    field: 'to',
    width: 280,
    headerName: t('To'),
    ...defaultConfig,
    renderCell: ({ value }) => `${value?.name} (${value?.username})`,
  },
  {
    field: 'transferredAt',
    headerName: t('Date'),
    width: 200,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
];

export default columnsConfig;
