import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import { PendingRedemptionsContext, PendingRedemptionsProvider } from './context';
import Table from '../../../components/Table';
import PayRedemptionModal from './components/PayRedemptionModal';

const PendingRedemptions = () => {
  const { navigate } = useNavigate();
  const {
    t,
    user,

    redemptionsDataColumnsConfiguration,
    redemptionsData,
    loadedRedemptionsData,
  } = useContext(PendingRedemptionsContext);

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container className="admin-pending-redemptions">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          {t('Pending Redemptions')}
        </Typography>
      </Layout>
      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedRedemptionsData ? (
          <Table
            loading={!loadedRedemptionsData}
            columns={redemptionsDataColumnsConfiguration}
            rows={redemptionsData}
            className="table mb-4"
          />
        ) : (
          <div className="flex justify-center">
            <ComponentLoader />
          </div>
        )}
      </Grid>
      <PayRedemptionModal />
    </Container>
  );
};

export default () => (
  <PendingRedemptionsProvider>
    <PendingRedemptions />
  </PendingRedemptionsProvider>
);
